body {
  background-color: #e5e5e5;
}

@media (min-width: 991px) {
  .product-page .section-header .product-header-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.product-page .section-header {
  min-height: 650px;
  height: 70vh;
  max-height: 930px;
  padding-top: 100px;
  position: relative;
}

/* .product-page .section-header h1 {
  font-weight: 200;
  letter-spacing: 0.0625em;
} */

.product-page .section-header .container {
  position: relative;
  z-index: 2;
}

.product-page .section-header .product-header-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.gradient-blue {
  background-color: #51c9f5;
  background: linear-gradient(-45deg, #0c9ed4 0%, #51c9f5 100%);
}

.product-page .product-image {
  box-shadow: 0 29px 32px -20px rgba(0, 0, 0, 0.35);
  margin: 0 auto 30px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  border-radius: 20px;
  height: 40vh;
}

.product-page .section-scaled {
  margin: -50px 30px 0;
  border-radius: 10px 10px 0 0;
}
.product-page .section-white-gradient {
  background: linear-gradient(0deg, #e5e5e5 0%, #fff 100%);
}
.product-page .section-white {
  background-color: #fff;
}
.product-page .section-product-description {
  position: relative;
  z-index: 3;
  padding: 20px 0 70px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  /* margin-top: -100px; */
  min-height: 30vh;
}
